/*!
 * Bootstrap Responsive v2.3.2
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */


// Responsive
// For phone and tablet devices
// -------------------------------------------------------------


// REPEAT VARIABLES & MIXINS
// -------------------------
// Required since we compile the responsive stuff separately

@import "variables"; // Modify this for custom colors, font-sizes, etc
@import "mixins";


// RESPONSIVE CLASSES
// ------------------

@import "responsive-utilities";


// MEDIA QUERIES
// ------------------

// Large desktops
@import "responsive-1200px-min";

// Tablets to regular desktops
@import "responsive-768px-979px";

// Phones to portrait tablets and narrow desktops
@import "responsive-767px-max";


// RESPONSIVE NAVBAR
// ------------------

// From 979px and below, show a button to toggle navbar contents
@import "responsive-navbar";
