//
// Responsive: Landscape phone to desktop/tablet
// --------------------------------------------------


@media (max-width: 767px) {
	// *{display: none !important;}
	.home .news-blog-section{
		padding: 20px;
	}
	.speech-bubble-right{
		background: none;
		.inner{
			padding: 10px;
			a{
				color:$black !important;
				&:hover{
					color:$white !important;
				}
			}
		}
	}
	#footer{
		padding-right: 0;
		.span3{
			float: left;
			width: 30%;
			margin-right: 3%;
		}
		.span4{
			float: left;
			width: 45%;
			margin-right: 5%;
		}
	}
	// Padding to set content in a bit
	body {
		background-position: 0 0 !important;
	}

	.svg .home .phone-header-svg{
		display: block !important;
	}
	.no-svg .home .phone-header-img{
		display: block !important;
	}

	// Negative indent the now static "fixed" navbar
	.navbar-fixed-top,
	.navbar-fixed-bottom,
	.navbar-static-top {
		margin-left: -20px;
		margin-right: -20px;
	}
	// Remove padding on container given explicit padding set on body
	.container-fluid {
		padding: 0 10px;
		&.rotator-container,
		&.home-widgets-container,
		&.extra-items-container,
		&.news-blog-container{
			padding: 0;

		}
	}

	// TYPOGRAPHY
	// ----------
	// Reset horizontal dl
	.dl-horizontal {
		dt {
			float: none;
			clear: none;
			width: auto;
			text-align: left;
		}
		dd {
			margin-left: 0;
		}
	}

	// GRID & CONTAINERS
	// -----------------
	// Remove width from containers
	.container {
		width: auto;
	}
	// Fluid rows
	.row-fluid {
		width: 100%;
	}
	// Undo negative margin on rows and thumbnails
	.row,
	.thumbnails {
		margin-left: 0;
	}
	.thumbnails > li {
		float: none;
		margin-left: 0; // Reset the default margin for all li elements when no .span* classes are present
	}
	// Make all grid-sized elements block level again
	[class*="span"],
	.uneditable-input[class*="span"], // Makes uneditable inputs full-width when using grid sizing
	.row-fluid [class*="span"] {
		float: none;
		display: block;
		width: 100%;
		margin-left: 0;
		@include box-sizing(border-box);
	}
	.span12,
	.row-fluid .span12 {
		width: 100%;
		@include box-sizing(border-box);
	}
	.row-fluid [class*="offset"]:first-child {
		margin-left: 0;
	}

	// FORM FIELDS
	// -----------
	// Make span* classes full width
	.input-large,
	.input-xlarge,
	.input-xxlarge,
	input[class*="span"],
	select[class*="span"],
	textarea[class*="span"],
	.uneditable-input {
		@include input-block-level();
	}
	// But don't let it screw up prepend/append inputs
	.input-prepend input,
	.input-append input,
	.input-prepend input[class*="span"],
	.input-append input[class*="span"] {
		display: inline-block; // redeclare so they don't wrap to new lines
		width: auto;
	}
	.controls-row [class*="span"] + [class*="span"] {
		margin-left: 0;
	}

	// Modals
	.modal {
		position: fixed;
		top:   20px;
		left:  20px;
		right: 20px;
		width: auto;
		margin: 0;
		&.fade  { top: -100px; }
		&.fade.in { top: 20px; }
	}

	// Mobile Layout changes
	// ----------------------
	body {
		padding-left: 0;
		padding-right: 0;
	}
	body.page .page-container,
	body.single .single-container,
	body.archive .archive-container{
		background-position: 0 0 !important;
	}
	.sidebar{
		position: relative;
		// display:none;
		// .home &{
		// 	display: block;
		// }
		width: 100%;
		float: left;
		// height: 100%;
		margin: 0;
		padding: 0;
		// padding-bottom: 40px;
		background: $grayDark;
		// overflow-y: auto;
		// z-index: 20;
		.sidenav-inner{
			float: left;
			clear: left;
			width: 90%;
			padding: 2% 5% 0;
			background: bottom left repeat-x url(../img/mobile-header-bg-bottom_2x48_repeatX.jpg) $grayDark;
		}
		a.brand, #menu-left-nav-menu{
			float: left;
			width: 40%;
			margin-right:5%;
			background-size: contain;
		}
		#menu-left-nav-menu{
			margin-right: 0;
			margin-left: 0;
			width: 55%;
			li a{
				font-size: 20px;

			}
		}

		.sidebar-aside{
			// float: left;
			// clear: left;
			background: none;
			// width: 90%;
			// padding: 5%;
			// display: none;
			height: 0;
			padding: 0;
		}
		.sidebar-lower{
			display: none;
		}
	}
	.rotator-container{
		clear: left;
	}
	.flexslider .slides > li .flex-caption{
		position: static;
		width: auto;
		background: $pink;
	}
	#menu-left-nav-menu{
		position: absolute;
		top: 100%;
		background:$grayDark;
		padding: 10px;
		display: none;
	}
	#google_translate_element{
		position: absolute;
		right: 10px;
		bottom: 3px;
	}
	#phone-navigate,
	#phone-sign-up{
		padding: 10px 3% 10px 0px;
		text-transform: uppercase;
	}
	.sidebar #menu-left-nav-menu li a,
	#phone-navigate,
	#phone-sign-up{
		@include f-lg(20, 24);
	}
	#phone-navigate,
	#phone-sign-up,
	#google_translate_element{
		float: left;
		// margin-right: 10px;
	}
	html, body{
		width: 100%;
		max-width:100%;
		overflow-x: hidden;
	}
	.clear-left-phone{
		clear: left;
	}
	.container-fluid{
		clear: left;
	}
	.home-widgets-container .widget{
		padding-right: 0;
	}
	.widget-bubble{
			background-image: 	none !important;
			background-color: $white !important;
	}
	.collection-widget{
		padding-right: 0 !important;
	}
	.rise-widget {
		padding: 10px !important;
	}
	// .rise-widget-signup{
	// 	text-align: center;
	// 	input[type=submit]{
	// 		margin: 10px auto !important;
	// 		width:140px !important;
	// 	}
	// }
}



// UP TO LANDSCAPE PHONE
// ---------------------

@media (max-width: 480px) {

	// Smooth out the collapsing/expanding nav
	.nav-collapse {
		-webkit-transform: translate3d(0, 0, 0); // activate the GPU
	}

	// Block level the page header small tag for readability
	.page-header h1 small {
		display: block;
		line-height: $baseLineHeight;
	}

	// Update checkboxes for iOS
	input[type="checkbox"],
	input[type="radio"] {
		border: 1px solid #ccc;
	}

	// Remove the horizontal form styles
	.form-horizontal {
		.control-label {
			float: none;
			width: auto;
			padding-top: 0;
			text-align: left;
		}
		// Move over all input controls and content
		.controls {
			margin-left: 0;
		}
		// Move the options list down to align with labels
		.control-list {
			padding-top: 0; // has to be padding because margin collaspes
		}
		// Move over buttons in .form-actions to align with .controls
		.form-actions {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	// Medias
	// Reset float and spacing to stack
	.media .pull-left,
	.media .pull-right  {
		float: none;
		display: block;
		margin-bottom: 10px;
	}
	// Remove side margins since we stack instead of indent
	.media-object {
		margin-right: 0;
		margin-left: 0;
	}

	// Modals
	.modal {
		top:   10px;
		left:  10px;
		right: 10px;
	}
	.modal-header .close {
		padding: 10px;
		margin: -10px;
	}

	// Carousel
	.carousel-caption {
		position: static;
	}

}
